import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { UserState } from '../../../../src/app.interface';
import { UserProfile } from 'common.interfaces';

const initialState: UserState = {
  userInfo: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => {
    return {
      setUserInfo: (userInfo: UserProfile | null | undefined) => patchState(store, { userInfo }),
    };
  }),
);
